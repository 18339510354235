@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}
::-webkit-scrollbar {
  width: 10px; /* lebar scrollbar */
}

/* Thumb (bagian yang dapat digerakkan) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* warna background thumb */
  border-radius: 5px; /* sudut bulatan thumb */
}

/* Track (bagian latar belakang scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* warna latar belakang track */
} 
* {
  scroll-behavior: smooth;
}

.font-judul {
  font-family: "Playfair Display", serif;
}

.font-p {
  font-family: "Rubik", sans-serif;
}
.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}
.about  .navbar li{
  color: white;
 }
.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}
.btn-product {
  color: aqua;
}
.btn-product:hover {
  color: #214d76 !important;
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}
.youtube-wrap {
  height: 70vh;
  width: 80%;
  margin: 0 auto;
  margin-top: 2rem;
}
.youtube-wrap iframe {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media only screen and (min-width: 1500px) {
  .swipperHero .splide__arrow {
    left: 6.2% !important;
  }

}
@media only screen and (min-width: 1400px) {
  #review .swiper-slide {
    width: 500px !important;
  }

}
@media only screen and (max-width: 1450px) {
  .swipperHero .splide__arrow {
    left: 6% !important;
  }
  .swipperHero .hero-1 .teks h1 {
    font-size: 66px !important;
  }

}
@media only screen and (max-width: 1350px) {
  .swipperHero .splide__arrow {
    left: 5.8% !important;
  }
  .swipperHero .hero-1 .teks h1 {
    font-size: 58px !important;
  }
  .youtube-wrap {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .card {
    min-height: 250px; /* Sesuaikan tinggi untuk layar kecil */
  }
  .font-judul {
    font-size: 20px; /* Sesuaikan ukuran font */
  }
  #partner .swiper-button-prev{
    right: 4rem !important;
  }
  #partner .swiper-button-next{
    right: 0.5rem !important;
  }
  #partner .partner-wrap{
    height: 40vh;
  }
  .swipperHero input {
    width: 100% !important;
  }
  #review .konten .swiper-slide {
  margin-right: 20px !important;

  width: 300px !important;
}
  .swiper-slide {
    width: 100px !important;
}
  #partner .konten {
    right: 0 !important;
    top: auto !important;
  }
  .swipperHero .splide__arrows--ttb .splide__arrow {
    left: 1.5em !important;
  }
  .swipperHero .hero-1 .teks h1 {
    font-size: 40px !important;
  }
  .swipperHero .hero-1 .teks {
    width: 85% !important;
    margin-top: 2rem !important;
    padding-right: 2rem !important;
  }
  .youtube-wrap {
    height: 200px;
  }
  .contact-hero {
    border-radius: 40px;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 0;
  }
  .contact-hero .input {
    margin: 0 2rem;
  }
}
@media only screen and (max-width: 400px) {
  .font-judul{
    font-size: 21px !important;
  }
  #partner .partner-wrap{
    height: 54vh ;
  }
  .contact-hero.bg-white{
    height: 280px;
  }
  .contact-hero.bg-white .input {
    font-size: 12px;
}
  .swipperHero .hero-1 .teks h1 {
    font-size: 38px !important;
  }
  .swipperHero .hero-1 .teks {
    width: 85% !important;
    margin-top: 4rem !important;
    padding-right: 2rem !important;
  }
}
.swipperHero .splide {
  padding: 0 !important;
}
.swipperHero .splide__pagination--ttb .splide__pagination__page {
  height: 16px !important;
  width: 16px !important;
  border-radius: 21px;  
}
.swipperHero .splide-track   {
  height: calc(0px + 100vh) !important;
}

.swipperHero .splide__pagination__page.is-active {
  background-color: red !important;
}
.swipperHero .splide__pagination {
  align-items: end !important ;
}
.swipperHero .hero-1 {
  display: grid;
  height: 100%;
  color: white;
  /* justify-content: center; */
}
.swipperHero .hero-1 .teks {
  width: 60%;
  margin-top: 10rem;
  justify-self: end;
  align-self: center;
  text-align: end;
  padding-right: 5rem;
}
ul.splide__list > li:nth-child(4) .hero-1 .teks {
  width: 70%;
  margin-top: 0;
  justify-self: center;
  align-self: center;
  padding-right: 0;
  text-align: center;
}
ul.splide__list > li:nth-child(5) .hero-1 .teks {
  width: 70%;
  margin-top: 0;
  justify-self: start;
  padding-left: 5rem;
  align-self: center;
  text-align: center;
}
ul.splide__list > li:nth-child(6)  .hero-1 .teks {
  width: 60%;
  margin-top: 10rem;
  justify-self: end;
  align-self: center;
  text-align: end;
  padding-right: 5rem;
}

.swipperHero .hero-1 .teks h1 {
  line-height: 1;
  font-size: 72px;
  text-shadow: 2px 2px 4px #000000;
}
.swipperHero .hero-1 .teks p {
  margin-top: 1rem;
  font-size: 22px;
}
.swipperHero .splide__slide {
  height: 100%;
}
.splide__track ul > li:nth-child(3) {
  background: url(assets/exp-1.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.splide__track ul > li:nth-child(4)  {
  background: url(assets/back.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.splide__track ul > li:nth-child(5) {
  background: url(assets/exp-5.jpg);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.splide__track ul > li:nth-child(6) {
  background: url(assets/exp-2.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

.swipperHero .splide__arrows--ttb .splide__arrow--prev {
  top: 52% !important;
}
.swipperHero .splide__arrows--ttb .splide__arrow--next {
  bottom: 41% !important;
  top: auto;
}
.swipperHero .splide__pagination__page.is-active {
  transform: scale(1) !important;
}
.swipperHero .splide__pagination {
  bottom: 4em !important;
  right: 92% !important;
}
.swipperHero .splide__arrow svg {
  width: 14px !important;
  height: 28px;
  fill: rgb(255, 119, 87) !important;
}
.swipperHero button.splide__pagination__page {
  background-color: #ffffff !important;
}
.swipperHero input {
  border-bottom: 1px solid;
  width: 80%;
}
li#nav-\/contact {
  background-color: rgb(255, 119, 87);
  padding: 9px 20px;
  color: white;
  border-radius: 8px;
}

/* about */
.line {
  border: 3px solid rgb(255, 119, 87);
  height: 0;
  margin-top: 5px;
}
.sub-title {
  margin: 1rem 0;
  margin-bottom: 1rem;
}
.image-about {
  background: url("assets/about-image.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* product */

#review .card {
  height: 470px;
}

figure.wp-block-gallery.has-nested-images.columns-default.is-cropped.is-layout-flex.wp-block-gallery-is-layout-flex{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  min-height: 200px; /* Ganti height dengan min-height */
}
/* award */
#awards .card {
  height: 330px;
}
#awards .card .image {
  width: 100%;
  height: 100%;
}
#awards .card .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#testi .card .image {
  width: 502px;
  height: 352px;
}
#testi .card .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* swipper */

#partner .konten {
  right: -20rem;
  top: 0;
}

#review .swiper-slide {
  width: 400px !important;
}
#review .card {
  box-shadow: none;
  border-radius: none;
}
section#review {
  height: 55vh;
}
.swiper {
  height: 15rem;
}
#review .swiper {
  height: 100%;
}
.swiper-slide {
  margin-top: 5rem !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 13px !important;
}
#review .swiper-button-next,
#review .swiper-button-prev {
  left: initial !important;
  height: 42px !important;
  top: 34rem !important;
  width: 40px !important;
  color: #fff !important;
  border-radius: 8px;
}
.swiper-button-next,
.swiper-button-prev {
  left: initial !important;
  height: 42px !important;
  width: 40px !important;
  color: #fff !important;
  margin-top: 5rem !important;
  border-radius: 8px;
}
.swiper-button-prev {
  right: 0 !important;
  background-color: #172432;
  margin-right: 4rem;
}
.swiper-button-next {
  background-color: #ff7757;
}
/* contact */
.wrap-iframe {
  height: 400px;
  width: 80%;
}

/* end section */
#endSection{
  background: url('./assets/end.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 5rem;
  height: 400px;

}
#endSection h1{
  color: white;
  line-height: 1;
  font-size: 34px;
  text-align: center;
}
/* kontak */
.whatsapp{
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 2rem;
  width: 50px;
  animation: bounce 0.5s; 
  animation-direction: alternate; 
  animation-timing-function: ease-in-out; 
  animation-iteration-count: infinite;  

}
@keyframes bounce { 
  from { 
      transform: translate3d(0, 10px, 0); 
  } 
  to { 
      transform: translate3d(0, -10px, 0); 
  } 
} 

.whatsapp img{
  width: 100%;
  object-fit: cover;
}

/* modal image */
 .wp-block-group.is-nowrap.is-layout-flex.wp-container-core-group-is-layout-1.wp-block-group-is-layout-flex{
  display: flex;
  align-items: center;
  margin: 1rem;
  flex-wrap: wrap;
  gap: .5rem;
}
 figure.wp-block-image {
  margin: auto;
width: 100px;
}
.customers{
  position: relative;
}

.text-solution p{
  height: 70%;
  overflow: hidden;
}
.customers .swiper-slide{
  width: 140px !important;
  height: 90px;
}


